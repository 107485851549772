<template>
  <div>
    <c-table
      ref="table"
      title="시설 자체점검 목록"
      tableId="facilitySelfCheckWeeksPop"
      :columns="grid.columns"
      :data="grid.data"
      gridHeight="500px"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      @linkClick="linkClick"
    >
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'hazard-facility-self-check-weeks-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          hcfHazardFacilitySelfCheckIds: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        hcfHazardFacilitySelfCheckIds: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'selfCheckName',
            field: 'selfCheckName',
            label: '점검명',
            align: 'left',
            style: 'width:250px',
            type: 'link',
            sortable: false,
          },
          {
            name: 'facilityName',
            field: 'facilityName',
            label: '시설명',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'facilityNo',
            field: 'facilityNo',
            label: '시설관리번호',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'hcfFacilityTypeName',
            field: 'hcfFacilityTypeName',
            label: '시설유형',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'selfCheckDate',
            field: 'selfCheckDate',
            label: '점검일자',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'hcfSelfCheckCompleteFlagName',
            field: 'hcfSelfCheckCompleteFlagName',
            label: '진행상태',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '주관부서',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'selfCheckUserName',
            field: 'selfCheckUserName',
            label: '점검자',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
        ],
        data: [],
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.env.facilitySelfCheck.list.url
      // code setting
      // list setting
      if (this.popupParam.hcfHazardFacilitySelfCheckIds) {
        this.searchParam.hcfHazardFacilitySelfCheckIds = this.popupParam.hcfHazardFacilitySelfCheckIds
      }
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '시설 자체점검 상세'; // 시설 자체점검 상세
      this.popupOptions.param = {
        hcfHazardFacilitySelfCheckId: row ? row.hcfHazardFacilitySelfCheckId : '',
      };
      this.popupOptions.target = () => import(`${'./hazardFacilitySelfCheckDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
